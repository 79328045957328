


















































import Vue, { PropType } from "vue";
import {
  AdminUserProfileResponse,
  ApiException,
  UsersAdminClient,
} from "@/clients/clients";
import { loginRequest } from "@/configs/authConfig";

export default Vue.extend({
  name: "AppDeleteTeamMemberDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object as PropType<AdminUserProfileResponse>,
    },
  },
  data: () => ({ deleteAccount: false }),
  computed: {
    firstName(): string {
      return this.item?.firstName ?? "";
    },
    lastName(): string {
      return this.item?.lastName ?? "";
    },
  },
  deactivated() {
    this.deleteAccount = false;
  },
  destroyed() {
    this.deleteAccount = false;
  },
  methods: {
    cancel(): void {
      this.deleteAccount = false;
      this.$emit("update:close");
    },
    async removeFromTeam(): Promise<void> {
      try {
        const token =
          (await this.$auth.acquireTokenSilent(loginRequest))?.accessToken ??
          "";
        const client = this.$httpClientFactory(UsersAdminClient, token);
        await client.removeFromTeam(this.item?.id ?? "");
      } catch (e) {
        this.$emit("update:error", (e as ApiException).message);
      } finally {
        this.$emit("update:team");
        this.$emit("update:close");
      }
    },
    async deleteUser(): Promise<void> {
      try {
        const token =
          (await this.$auth.acquireTokenSilent(loginRequest))?.accessToken ??
          "";
        const client = this.$httpClientFactory(UsersAdminClient, token);
        await client.deleteUser(this.item?.id ?? "");
      } catch (e) {
        this.$emit("update:error", (e as ApiException).message);
      } finally {
        this.$emit("update:team");
        this.$emit("update:close");
      }
    },
  },
});
