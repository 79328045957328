

























































import Vue from "vue";
import { ApiException, EnquiriesAdminClient } from "@/clients/clients";
import { loginRequest } from "@/configs/authConfig";

export default Vue.extend({
  name: "AppUploadEnquiriesCsvDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    submitted: false,
    file: null as File | null,
    errors: [] as string[],
    valid: true,
  }),
  computed: {
    rules: (): { (value: File): boolean }[] => [
      (value: File): boolean => !value || value.size < 2000000,
      (value: File): boolean =>
        !value ||
        /text\/csv|application\/vnd.ms-excel|application\/vnd.openxmlformats-officedocument\.spreadsheetml\.sheet/.test(
          value.type
        ),
    ],
  },
  deactivated() {
    this.submitted = false;
    this.file = null;
  },
  destroyed() {
    this.submitted = false;
    this.file = null;
  },
  methods: {
    close() {
      this.submitted = false;
      this.file = null;
      this.$emit("update:close");
    },
    validate() {
      this.valid = (this.$refs["form"] as any).validate();
      if (!this.valid) {
        this.errors = ["Form is not valid"];
      } else {
        this.errors = [];
      }
    },
    async submit() {
      try {
        this.valid = (this.$refs["form"] as any).validate();
        if (!this.valid) {
          this.errors = ["Form is not valid"];
          return;
        }
        this.errors = [];
        const tokenRequest = await this.$auth.acquireTokenSilent(loginRequest);
        const client = this.$httpClientFactory(
          EnquiriesAdminClient,
          tokenRequest.accessToken
        );
        await client.uploadCsv({
          data: this.file,
          fileName: this.file?.name ?? "",
        });
        this.submitted = true;
        this.file = null;
        this.valid = true;
      } catch (e) {
        console.log(e);
        this.valid = false;
        this.errors = [(e as ApiException).message];
      }
    },
  },
});
