



































































































































































import Vue from "vue";
import {
  AddNewTeamAccountRequest,
  AdminUserProfileResponse,
  ApiException,
  UserRoleType,
  UsersAdminClient,
} from "@/clients/clients";
import { loginRequest } from "@/configs/authConfig";

export default Vue.extend({
  name: "AppAddTeamMemberForm",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    step: 1,
    display: false,
    newMember: new AdminUserProfileResponse({
      id: "",
      email: "",
      admin: true,
      adminRoles: [],
      firstName: "",
      lastName: "",
      createdAt: new Date(),
      updatedAt: new Date(),
      phoneNumber: "",
      title: "",
    }),
    accountExists: false,
  }),
  computed: {
    adminRoles(): { text: string; value: UserRoleType }[] {
      return Object.values(UserRoleType).map((roleType) => {
        switch (roleType) {
          case UserRoleType.UserAdmin:
            return { text: "User admin", value: UserRoleType.UserAdmin };
          case UserRoleType.EnquiriesAdmin:
            return {
              text: "Enquiries admin",
              value: UserRoleType.EnquiriesAdmin,
            };
          case UserRoleType.WebinarAdmin:
            return { text: "Webinar admin", value: UserRoleType.WebinarAdmin };
          case UserRoleType.TeamAdmin:
            return { text: "Team admin", value: UserRoleType.TeamAdmin };
          case UserRoleType.GlobalAdmin:
            return { text: "Global admin", value: UserRoleType.GlobalAdmin };
        }
      });
    },
  },
  deactivated() {
    this.reset();
  },
  destroyed() {
    this.reset();
  },
  methods: {
    onClickOutside() {
      this.reset();
      this.$emit("click:outside");
    },
    cancel() {
      this.reset();
      this.$emit("update:close");
    },
    reset() {
      this.newMember = Object.assign(
        {},
        new AdminUserProfileResponse({
          id: "",
          email: "",
          admin: true,
          adminRoles: [],
          firstName: "",
          lastName: "",
          createdAt: new Date(),
          updatedAt: new Date(),
          phoneNumber: "",
          title: "",
        })
      );
      this.step = 1;
      this.display = false;
      this.accountExists = false;
    },
    async getUserByEmail(email: string): Promise<void> {
      try {
        const token =
          (await this.$auth.acquireTokenSilent(loginRequest))?.accessToken ??
          "";
        const client = this.$httpClientFactory(UsersAdminClient, token);
        const member = await client.getTeamMemberByEmail(email);
        this.newMember = Object.assign({}, member);
        this.accountExists = true;
      } catch (e) {
        this.accountExists = false;
        this.newMember.email = email;
      } finally {
        this.step = 2;
      }
    },
    async addTeamMember(): Promise<void> {
      try {
        const token =
          (await this.$auth.acquireTokenSilent(loginRequest))?.accessToken ??
          "";
        const client = this.$httpClientFactory(UsersAdminClient, token);
        const request = new AddNewTeamAccountRequest(this.newMember);
        await client.addTeamMember(request);
        this.$emit("update:team");
        this.$emit("update:close");
      } catch (e) {
        this.$emit("update:error", (e as ApiException).message);
      }
    },
  },
});
