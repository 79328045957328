


































































import Vue from "vue";
import {
  ApiException,
  IWebinarDashboardAdminResponse,
  OrderBy,
  WebinarAvailability,
  WebinarsAdminClient,
  WebinarSort,
  WebinarStatus,
} from "@/clients/clients";
import { loginRequest } from "@/configs/authConfig";
import AppWebinarsTable from "@/components/tables/AppWebinarsTable.vue";
import AppPageBanner from "@/components/layout/AppPageBanner.vue";
import AppPage from "@/components/layout/AppPage.vue";
import { HealthCareInterests } from "@/providers/HealthCareInterests";
import { DataOptions } from "vuetify";

export default Vue.extend({
  name: "WebinarsDashboardView",
  components: { AppPage, AppPageBanner, AppWebinarsTable },
  data: () => ({
    loading: true,
    webinars: [] as IWebinarDashboardAdminResponse[],
    totalWebinars: 0,
    topics: [],
    availability: null as WebinarAvailability | null,
    status: null as WebinarStatus | null,
    noDataText: "",
    tableOptions: {
      page: 1,
      itemsPerPage: 15,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
  }),
  computed: {
    webinarTopics(): string[] {
      return HealthCareInterests;
    },
    webinarAvailability(): WebinarAvailability[] {
      return [WebinarAvailability.Live, WebinarAvailability.OnDemand];
    },
    webinarStatus(): WebinarStatus[] {
      return [WebinarStatus.Draft, WebinarStatus.Published];
    },
  },
  async created() {
    await this.getWebinars();
  },
  methods: {
    async updateOptions(options: DataOptions) {
      this.tableOptions = Object.assign({}, this.tableOptions, options);
      await this.getWebinars();
    },
    async resetOptions() {
      this.tableOptions = Object.assign({}, this.tableOptions, {
        page: 1,
        itemsPerPage: 15,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      });
      await this.getWebinars();
    },
    async onReset(): Promise<void> {
      (this.$refs["filterForm"] as HTMLFormElement).reset();
      this.topics = [];
      this.availability = null;
      this.status = null;
      this.tableOptions = Object.assign(
        {},
        {
          page: 1,
          itemsPerPage: 15,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: false,
        }
      );
      await this.getWebinars();
    },
    async getWebinars(): Promise<void> {
      this.loading = true;
      let accessToken = "";

      try {
        const token = await this.$auth.acquireTokenSilent(loginRequest);
        accessToken = token.accessToken;
      } catch (e) {
        await this.$auth.loginRedirect(loginRequest);
      }

      const query = this.getWebinarsQuery();
      try {
        const client = this.$httpClientFactory(
          WebinarsAdminClient,
          accessToken
        );
        const result = await client.getWebinars(
          query.all,
          query.offset,
          query.limit,
          query.topics,
          query.availability,
          query.status,
          query.featured,
          query.sort,
          query.order
        );
        if (result.webinars) {
          this.webinars = [...result.webinars];
        }
        this.totalWebinars = result.total ?? 0;
      } catch (e) {
        this.noDataText = `Error fetching data`;
        const message = (e as ApiException).message;
        this.noDataText = message
          ? `${this.noDataText}: ${message}`
          : this.noDataText;
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    getWebinarsQuery(): {
      all?: boolean | undefined;
      offset?: number | undefined;
      limit?: number | undefined;
      topics?: string[] | undefined;
      availability?: WebinarAvailability | undefined;
      status?: WebinarStatus | undefined;
      featured?: boolean | undefined;
      sort?: WebinarSort | undefined;
      order?: OrderBy | undefined;
    } {
      if (this.tableOptions.itemsPerPage === -1) return { all: true };
      const query: {
        all?: boolean | undefined;
        offset?: number | undefined;
        limit?: number | undefined;
        topics?: string[] | undefined;
        availability?: WebinarAvailability | undefined;
        status?: WebinarStatus | undefined;
        featured?: boolean | undefined;
        sort?: WebinarSort | undefined;
        order?: OrderBy | undefined;
      } = {
        topics: this.topics.length ? this.topics : undefined,
        availability: this.availability ? this.availability : undefined,
        status: this.status ? this.status : undefined,
        offset: (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage,
        limit: this.tableOptions.itemsPerPage,
        sort: undefined,
        order: undefined,
      };
      if (
        this.tableOptions.sortBy.length &&
        this.tableOptions.sortDesc.length
      ) {
        query.order = this.tableOptions.sortDesc[0]
          ? OrderBy.DESC
          : OrderBy.ASC;
        switch (this.tableOptions.sortBy[0]) {
          case "topic":
            query.sort = WebinarSort.ByTopic;
            break;
          case "status":
            query.sort = WebinarSort.ByStatus;
            break;
          case "availability":
            query.sort = WebinarSort.ByAvailability;
            break;
          case "updatedAt":
            query.sort = WebinarSort.ByUpdatedAt;
            break;
          default:
            query.sort = WebinarSort.ByDate;
            break;
        }
      }
      return query;
    },
  },
});
