
























import Vue, { PropType } from "vue";
import { AdminUserProfileResponse, UserRoleType } from "@/clients/clients";
import AppTeamRoleSelection from "@/components/forms/team/AppTeamRoleSelection.vue";

export default Vue.extend({
  name: "AppTeamTable",
  components: { AppTeamRoleSelection },
  data: () => ({
    headers: [
      { text: "First name", value: "firstName" },
      { text: "Last name", value: "lastName" },
      { text: "Email", value: "email" },
      { text: "Last updated", value: "updatedAt" },
      { text: "Roles", value: "adminRoles", sortable: false },
      { text: "Delete", value: "delete", sortable: false },
    ],
  }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    teamMembers: {
      type: Array as PropType<AdminUserProfileResponse[]>,
      default: () => [],
    },
  },
  computed: {
    adminRoles(): { text: string; value: UserRoleType }[] {
      return Object.values(UserRoleType).map((roleType) => {
        switch (roleType) {
          case UserRoleType.UserAdmin:
            return { text: "User admin", value: UserRoleType.UserAdmin };
          case UserRoleType.EnquiriesAdmin:
            return { text: "Orders admin", value: UserRoleType.EnquiriesAdmin };
          case UserRoleType.WebinarAdmin:
            return { text: "Webinar admin", value: UserRoleType.WebinarAdmin };
          case UserRoleType.TeamAdmin:
            return { text: "Team admin", value: UserRoleType.TeamAdmin };
          case UserRoleType.GlobalAdmin:
            return { text: "Global admin", value: UserRoleType.GlobalAdmin };
        }
      });
    },
  },
  methods: {
    getRoles(userRoles: UserRoleType[] | null): string {
      return userRoles?.length
        ? userRoles
            .map((roleType) => {
              switch (roleType) {
                case UserRoleType.GlobalAdmin:
                  return "Global admin";
                case UserRoleType.UserAdmin:
                  return "User admin";
                case UserRoleType.EnquiriesAdmin:
                  return "Orders admin";
                case UserRoleType.WebinarAdmin:
                  return "Webinar admin";
              }
            })
            .join(", ")
        : "None";
    },
    updateRole(item: AdminUserProfileResponse) {
      console.log(item.adminRoles);
    },
  },
});
