


























































import Vue from "vue";
import AppPageBanner from "@/components/layout/AppPageBanner.vue";
import AppPage from "@/components/layout/AppPage.vue";
import AppTeamTable from "@/components/tables/AppTeamTable.vue";
import {
  AdminUserProfileResponse,
  ApiException,
  UsersAdminClient,
} from "@/clients/clients";
import { loginRequest } from "@/configs/authConfig";
import AppAddTeamMemberForm from "@/components/forms/team/AppAddTeamMemberForm.vue";
import AppDeleteTeamMemberDialog from "@/components/dialogs/AppDeleteTeamMemberDialog.vue";

export default Vue.extend({
  name: "TeamDashBoardView",
  components: {
    AppDeleteTeamMemberDialog,
    AppAddTeamMemberForm,
    AppTeamTable,
    AppPage,
    AppPageBanner,
  },
  data: () => ({
    loading: false,
    errorMessage: null as string | null,
    total: 0,
    team: [] as AdminUserProfileResponse[],
    addTeamDialog: false,
    deleteDialog: false,
    toDelete: null as AdminUserProfileResponse | null,
  }),
  async created() {
    await this.updateTeam();
  },
  methods: {
    closeDeleteDialog() {
      this.deleteDialog = false;
      this.toDelete = null;
    },
    deleteUser(item: AdminUserProfileResponse) {
      this.toDelete = item;
      this.deleteDialog = true;
    },
    async updateRole(event: AdminUserProfileResponse) {
      this.loading = true;
      try {
        const token = (await this.$auth.acquireTokenSilent(loginRequest))
          .accessToken;
        const client = this.$httpClientFactory(UsersAdminClient, token);
        await client.updateTeamMemberRoles(
          event?.id ?? "",
          event?.adminRoles ?? []
        );
        const updatedTeam = await client.getTeam();
        this.team = updatedTeam.admins ?? [];
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.loading = false;
      }
    },
    async updateTeam(): Promise<void> {
      this.loading = true;
      this.addTeamDialog = false;
      try {
        const token = (await this.$auth.acquireTokenSilent(loginRequest))
          .accessToken;
        const client = this.$httpClientFactory(UsersAdminClient, token);
        const teamResult = await client.getTeam();
        this.team = teamResult.admins ?? [];
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.loading = false;
      }
    },
  },
});
