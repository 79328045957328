






























import Vue, { PropType } from "vue";
import { AdminUserProfileResponse, UserRoleType } from "@/clients/clients";

export default Vue.extend({
  name: "AppTeamRoleSelection",
  data: () => ({
    member: null as AdminUserProfileResponse | null,
  }),
  props: {
    teamMember: {
      type: Object as PropType<AdminUserProfileResponse>,
    },
  },
  computed: {
    adminRoles(): { text: string; value: UserRoleType }[] {
      return Object.values(UserRoleType).map((roleType) => {
        switch (roleType) {
          case UserRoleType.UserAdmin:
            return { text: "User admin", value: UserRoleType.UserAdmin };
          case UserRoleType.EnquiriesAdmin:
            return { text: "Enquiries admin", value: UserRoleType.EnquiriesAdmin };
          case UserRoleType.WebinarAdmin:
            return { text: "Webinar admin", value: UserRoleType.WebinarAdmin };
          case UserRoleType.TeamAdmin:
            return { text: "Team admin", value: UserRoleType.TeamAdmin };
          case UserRoleType.GlobalAdmin:
            return { text: "Global admin", value: UserRoleType.GlobalAdmin };
        }
      });
    },
  },
  created() {
    this.member = Object.assign({}, this.teamMember);
  },
});
