







































































import Vue from "vue";
import AppPage from "@/components/layout/AppPage.vue";
import AppPageBanner from "@/components/layout/AppPageBanner.vue";
import {
  ApiException,
  EnquiriesAdminClient,
  Enquiry,
  EnquirySortBy,
  EnquiryStatusType,
  EnquiryType,
  OrderBy,
} from "@/clients/clients";
import { DataOptions } from "vuetify";
import { loginRequest } from "@/configs/authConfig";
import AppEnquiriesTable from "@/components/tables/AppEnquiriesTable.vue";
import AppUploadEnquiriesCsvDialog from "@/components/dialogs/AppUploadEnquiriesCsvDialog.vue";
import enquiryTypeLabelMapper from "@/providers/enquiryTypeLabelMapper";

export default Vue.extend({
  name: "EnquiriesDashboardView",
  components: {
    AppUploadEnquiriesCsvDialog,
    AppEnquiriesTable,
    AppPageBanner,
    AppPage,
  },
  data: () => ({
    loading: false,
    total: 0,
    enquiries: [] as Enquiry[],
    noDataText: "",
    status: null as EnquiryStatusType | null,
    enquiryTypes: [] as EnquiryType[],
    upload: false,
    tableOptions: {
      page: 1,
      itemsPerPage: 15,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
  }),
  computed: {
    enquiryStatus(): EnquiryStatusType[] {
      return [
        EnquiryStatusType.Open,
        EnquiryStatusType.Accepted,
        EnquiryStatusType.Completed,
        EnquiryStatusType.Rejected,
      ];
    },
    enquiryTypeOptions(): { text: string; value: EnquiryType }[] {
      return Object.keys(EnquiryType)
        .map((item: string) => {
          const type = (EnquiryType as unknown as { [key: string]: string })[
            item
          ] as EnquiryType;
          return {
            text: enquiryTypeLabelMapper(type),
            value: type,
          };
        })
        .filter((item) => item.value != EnquiryType.None);
    },
  },
  async created() {
    await this.getEnquiries();
  },
  methods: {
    async updateOptions(options: DataOptions): Promise<void> {
      this.tableOptions = Object.assign({}, this.tableOptions, options);
      await this.getEnquiries();
    },
    async onReset(): Promise<void> {
      (this.$refs["filterForm"] as HTMLFormElement).reset();
      this.status = null;
      this.tableOptions = Object.assign({}, this.tableOptions, {
        page: 1,
        itemsPerPage: 15,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      });
      await this.getEnquiries();
    },
    async onUpdate(): Promise<void> {
      await this.getEnquiries();
    },
    async resetOptions(): Promise<void> {
      this.tableOptions = Object.assign({}, this.tableOptions, {
        page: 1,
        itemsPerPage: 15,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      });
      await this.getEnquiries();
    },
    async getEnquiries(): Promise<void> {
      this.loading = true;
      try {
        const token = await this.$auth.acquireTokenSilent(loginRequest);
        const client = this.$httpClientFactory(
          EnquiriesAdminClient,
          token.accessToken
        );
        const query = this.buildQuery();

        const result = await client.getEnquiries(
          query.all,
          query.offset,
          query.limit,
          query.enquiryTypes,
          query.status,
          query.sort,
          query.order
        );
        this.enquiries = result.enquiries ?? [];
        this.total = result.total ?? 0;
      } catch (e) {
        this.noDataText = (e as ApiException).message;
      } finally {
        this.loading = false;
      }
    },
    buildQuery(): {
      all?: boolean | undefined;
      offset?: number | undefined;
      limit?: number | undefined;
      enquiryTypes?: EnquiryType[] | undefined;
      status?: EnquiryStatusType | undefined;
      sort?: EnquirySortBy | undefined;
      order?: OrderBy | undefined;
    } {
      const query: {
        all?: boolean | undefined;
        offset?: number | undefined;
        limit?: number | undefined;
        enquiryTypes?: EnquiryType[] | undefined;
        status?: EnquiryStatusType | undefined;
        sort?: EnquirySortBy | undefined;
        order?: OrderBy | undefined;
      } = {
        all: this.tableOptions.itemsPerPage === -1,
        offset: (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage,
        limit: this.tableOptions.itemsPerPage,
        enquiryTypes: this.enquiryTypes,
        status: this.status ?? undefined,
        sort: EnquirySortBy.ByEnquiryNumber,
        order: OrderBy.DESC,
      };

      if (
        this.tableOptions.sortBy.length &&
        this.tableOptions.sortDesc.length
      ) {
        query.order = this.tableOptions.sortDesc[0]
          ? OrderBy.DESC
          : OrderBy.ASC;
        switch (this.tableOptions.sortBy[0]) {
          case "enquiryNumber":
            query.sort = EnquirySortBy.ByEnquiryNumber;
            break;
          case "source":
            query.sort = EnquirySortBy.BySource;
            break;
          case "firstName":
            query.sort = EnquirySortBy.ByFirstName;
            break;
          case "lastName":
            query.sort = EnquirySortBy.ByLastName;
            break;
          case "email":
            query.sort = EnquirySortBy.ByEmail;
            break;
          case "enquiryStatus":
            query.sort = EnquirySortBy.ByEnquiryStatus;
            break;
          case "createdAt":
            query.sort = EnquirySortBy.ByRequestDate;
            break;
          case "updatedAt":
            query.sort = EnquirySortBy.ByLastUpdated;
            break;
          default:
            query.sort = undefined;
            break;
        }
      }
      return query;
    },
  },
});
